import { twJoin } from "tailwind-merge";
import Table from "../../../components/tables/base";

type Props = {
  data: any;
};

const DassResult = ({ data }: Props) => {
  return (
    <>
      <div className="w-full py-4">
        <strong className="py-4 block">{data.name}</strong>
        <div className="py-2">
          <div className="flex items-center justify-between">
            <strong>Interpretasi Depression Scale</strong>
            <div className="bg-blue-100 py-1 px-2 rounded-lg font-bold">
              {data?.result?.depression ?? 0}
            </div>
          </div>
          <div className="mt-3 border">
            <Table>
              <Table.Thead>
                <Table.Th className="text-center">Normal</Table.Th>
                <Table.Th className="text-center">Depresi Ringan</Table.Th>
                <Table.Th className="text-center">Depresi Sedang</Table.Th>
                <Table.Th className="text-center">Depresi Berat</Table.Th>
                <Table.Th className="text-center">
                  Depresi Sangat Berat
                </Table.Th>
              </Table.Thead>
              <Table.Tr>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 0 &&
                      data?.result?.depression <= 9 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  0-9
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 10 &&
                      data?.result?.depression <= 13 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  10-13
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 14 &&
                      data?.result?.depression <= 20 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  14-20
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 21 &&
                      data?.result?.depression <= 27 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  21-27
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 28 &&
                      data?.result?.depression <= 42 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  28-42
                </Table.Td>
              </Table.Tr>
            </Table>
          </div>
        </div>

        <div className="py-2 mt-3">
          <div className="flex items-center justify-between">
            <strong>Interpretasi Anxiety Scale</strong>
            <div className="bg-blue-100 py-1 px-2 rounded-lg font-bold">
              {data?.result?.anxiety ?? 0}
            </div>
          </div>
          <div className="mt-3 border">
            <Table>
              <Table.Thead>
                <Table.Th className="text-center">Normal</Table.Th>
                <Table.Th className="text-center">Kecemasan Ringan</Table.Th>
                <Table.Th className="text-center">Kecemasan Sedang</Table.Th>
                <Table.Th className="text-center">Kecemasan Berat</Table.Th>
                <Table.Th className="text-center">
                  Kecemasan Sangat Berat
                </Table.Th>
              </Table.Thead>
              <Table.Tr>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.anxiety >= 0 &&
                      data?.result?.anxiety <= 7 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  0-7
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.anxiety >= 8 &&
                      data?.result?.anxiety <= 9 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  8-9
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.anxiety >= 10 &&
                      data?.result?.anxiety <= 14 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  10-14
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.anxiety >= 15 &&
                      data?.result?.anxiety <= 19 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  15-19
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.anxiety >= 20 &&
                      data?.result?.anxiety <= 42 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  20-42
                </Table.Td>
              </Table.Tr>
            </Table>
          </div>
        </div>

        <div className="py-2 mt-3">
          <div className="flex items-center justify-between">
            <strong>Interpretasi Stress Scale</strong>
            <div className="bg-blue-100 py-1 px-2 rounded-lg font-bold">
              {data?.result?.stress ?? 0}
            </div>
          </div>
          <div className="mt-3 border">
            <Table>
              <Table.Thead>
                <Table.Th className="text-center">Normal</Table.Th>
                <Table.Th className="text-center">Stress Ringan</Table.Th>
                <Table.Th className="text-center">Stress Sedang</Table.Th>
                <Table.Th className="text-center">Stress Berat</Table.Th>
                <Table.Th className="text-center">Stress Sangat Berat</Table.Th>
              </Table.Thead>
              <Table.Tr>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.stress >= 0 &&
                      data?.result?.stress <= 14 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  0-14
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.stress >= 15 &&
                      data?.result?.stress <= 18 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  15-18
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.stress >= 19 &&
                      data?.result?.stress <= 25 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  19-25
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 26 &&
                      data?.result?.depression <= 33 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  26-33
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 34 &&
                      data?.result?.depression <= 42 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  34-42
                </Table.Td>
              </Table.Tr>
            </Table>
          </div>
        </div>

        <div className="py-2 mt-3">
          <div className="flex items-center justify-between">
            <strong>Interpretasi General Psychological Distress Scale</strong>
            <div className="bg-blue-100 py-1 px-2 rounded-lg font-bold">
              {data?.result?.total ?? 0}
            </div>
          </div>
          <div className="mt-3 border">
            <Table>
              <Table.Thead>
                <Table.Th className="text-center">Normal</Table.Th>
                <Table.Th className="text-center">Ringan</Table.Th>
                <Table.Th className="text-center">Sedang</Table.Th>
                <Table.Th className="text-center">Berat</Table.Th>
                <Table.Th className="text-center">Sangat Berat</Table.Th>
              </Table.Thead>
              <Table.Tr>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.total >= 0 &&
                      data?.result?.total <= 30 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  0-30
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.total >= 31 &&
                      data?.result?.total <= 40 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  15-18
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.total >= 41 &&
                      data?.result?.total <= 60 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  19-25
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 61 &&
                      data?.result?.depression <= 70 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  61-70
                </Table.Td>
                <Table.Td
                  className={twJoin(
                    `text-center ${
                      data?.result?.depression >= 71 &&
                      data?.result?.depression <= 126 &&
                      "bg-gray-100"
                    }`
                  )}
                >
                  71-126
                </Table.Td>
              </Table.Tr>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DassResult;
