import { formatString } from "../../../helper/string";

type Props = {
  data: any;
};

const CriticalIncidentResult = ({ data }: Props) => {
  return (
    <div className="w-full py-4">
      <strong>{data.name}</strong>
      <div className="mt-1 test-result-docs">
        {data.result &&
          data.result?.map((item: any, index: number) => (
            <div key={index}>
              <h1 className="font-semibold">Pertanyaan ke-{index + 1}</h1>
              {Object.entries(item).map(([key, value]) => (
                <div key={key} className="mb-2 border-b pb-2">
                  <small className="font-semibold">{formatString(key)}:</small>
                  <p>
                    {typeof value === "string" || typeof value === "number"
                      ? value
                      : JSON.stringify(value)}
                  </p>
                </div>
              ))}
            </div>
          ))}
        {/* {data.result && !data.result.value && (
          <div dangerouslySetInnerHTML={{ __html: data.result }} />
        )} */}
      </div>
    </div>
  );
};

export default CriticalIncidentResult;
