import { Controller, FormProvider, useForm } from "react-hook-form";
import Layout from "../../layout.tsx/app";
import { FormInput } from "../../../components/forms/input";
import { useEffect, useState } from "react";
import { FormSelect } from "../../../components/forms/input-select";
import { Spinner, Textarea } from "flowbite-react";
import "./style.css";
import { Button } from "../../../components/buttons";
import { Editor } from "@tinymce/tinymce-react";
import { request } from "../../../api/config";
import { useAlert } from "../../../stores/alert";
import { useNavigate, useParams } from "react-router-dom";
import { TestType } from "../../../types/assessment-tools/test";

type EditFormType = {
  name: string;
  type: { label: string; value: string | number } | null;
  desc: string;
  hint: string;
  soal: string;
  time: number;
};

type ErrorFormType = {
  name: [] | null;
  type: [] | null;
  desc: [] | null;
  hint: [] | null;
  soal: [] | null;
  time: [] | null;
};

const EditTestTool = () => {
  const [errors, setErrors] = useState<ErrorFormType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [test, setTest] = useState<TestType | null>(null);

  const { setMessage } = useAlert();
  const { id } = useParams();

  const forms = useForm<EditFormType>();
  const navigate = useNavigate();

  const options = [
    { label: "IN BASKET", value: "in basket" },
    { label: "CASE ANALYSIS", value: "case analysis" },
    { label: "ROLE PLAY", value: "role play" },
    { label: "GRAFIS", value: "grafis" },
  ];

  const onSubmit = forms.handleSubmit(async (data) => {
    setLoading(true);
    try {
      const payload = {
        ...data,
        type: data.type?.value,
      };
      await request.post(`/tools/update-test/${test?.id}`, payload);
      setMessage("Berhasil mengubah alat tes", "success");
      navigate("/tools/test-tools");
    } catch (err: any) {
      setErrors(err.response.data.errors);
    }
    setLoading(false);
  });

  const getTest = async () => {
    try {
      const { data } = await request.get(`tools/get-test-tool/${id}`);
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Promise.all([getTest()]).then((res) => {
      const testval: TestType = res[0];
      setTest(testval);
      forms.setValue("name", testval.name);
      forms.setValue(
        "type",
        options.find((val) => val.value === testval.type) ?? null
      );
      forms.setValue(
        "desc",
        testval ? JSON.parse(testval.additional_data).about.value : ""
      );
      forms.setValue("soal", testval.detail[0].question);
      forms.setValue(
        "hint",
        testval ? JSON.parse(testval.additional_data).kesiapan.value : ""
      );
      forms.setValue("time", testval.time);
    });
    setLoading(false);
  }, []);

  return (
    <>
      <Layout withPageTitle title="Edit Alat Tes">
        <div className="w-full max-w-[900px] bg-white">
          <FormProvider {...forms}>
            <FormInput
              name="name"
              control={forms.control}
              label="Nama Alat Tes"
              error={errors?.name}
            />
            <FormSelect
              name="type"
              label="Pilih Kategori"
              options={options}
              control={forms.control}
            />
            <div className="py-2">
              <label className="block mb-1">Deskripsi Singkat</label>
              <Textarea
                className="bg-white"
                {...forms.register("desc")}
                rows={8}
              />
            </div>
            <div className="py-2">
              <label className="block mb-1">Petunjuk Singkat</label>
              <Textarea
                className="bg-white"
                {...forms.register("hint")}
                rows={8}
              />
            </div>
            <div className="py-2">
              <label className="block mb-1">Soal</label>
              <Controller
                control={forms.control}
                name="soal"
                render={({ field }) => (
                  <Editor
                    initialValue={field.value}
                    apiKey={process.env.REACT_APP_TINYMCE_API}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: ["image"],
                      toolbar:
                        "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | image",
                      // images_upload_handler: handleImageUpload,
                      images_upload_handler: (blobInfo) => {
                        const base64str =
                          "data:" +
                          blobInfo.blob().type +
                          ";base64," +
                          blobInfo.base64();
                        return Promise.resolve(base64str);
                      },
                    }}
                    onEditorChange={(content, editor) =>
                      field.onChange(content)
                    }
                  />
                )}
              />
              {errors?.soal && (
                <small className="text-red-600">{errors?.soal}</small>
              )}
            </div>
            <FormInput
              name="time"
              control={forms.control}
              label="Durasi (dalam detik)"
              type="number"
              error={errors?.time}
              hint={
                "Biarkan isian ini berisi 0 jika tes ini tidak memiliki waktu"
              }
            />
            <div className="flex items-center justify-end">
              <Button className="px-8" onClick={onSubmit}>
                {loading ? <Spinner /> : "Simpan"}
              </Button>
            </div>
          </FormProvider>
        </div>
      </Layout>
    </>
  );
};

export default EditTestTool;
