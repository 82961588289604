import { Check } from "@phosphor-icons/react";
import Table from "../../../components/tables/base";

type Props = {
  data: any;
};

const SentenceResult = ({ data }: Props) => {
  return (
    <>
      <div className="w-full py-2 mt-4">
        <div className="flex justify-between items-center">
          <strong>{data.name}</strong>
        </div>
      </div>
      <div className="mt-3">
        <Table>
          <Table.Thead>
            <Table.Th>No</Table.Th>
            <Table.Th>Pertanyaan</Table.Th>
            <Table.Th>Jawaban</Table.Th>
            <Table.Th></Table.Th>
          </Table.Thead>
          <Table.Tbody>
            {data?.result?.map((item: any, key: number) => (
              <Table.Tr
                key={key}
                className={`${
                  item.answer === parseInt(item.key) ? "bg-gray-200" : ""
                }`}
              >
                <Table.Td>
                  <>{key + 1}</>
                </Table.Td>
                <Table.Td>{item.question ?? "-"}</Table.Td>
                <Table.Td>{item.answer ?? "-"}</Table.Td>
                <Table.Td>
                  {item.answer === parseInt(item.key) ? (
                    <Check className="text-green-600" />
                  ) : (
                    ""
                  )}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </div>
    </>
  );
};

export default SentenceResult;
