type Props = {
  data: any;
};

const GadPhqResult = ({ data }: Props) => {
  // console.log(data);
  return (
    <div className="py-4">
      <div className="w-full flex items-center justify-between">
        <strong className="py-4 block">{data.name}</strong>
        <div className="bg-blue-100 py-1 px-2 rounded-lg font-bold">
          {data?.result ?? 0}
        </div>
      </div>

      {data.name === "GAD-7" && (
        <>
          <div className="text-sm font-semibold">Keterangan</div>
          <div className="flex items-center">
            <div className="w-32">5 - 9</div>
            <div>: Ringan</div>
          </div>
          <div className="flex items-center">
            <div className="w-32">10 - 14</div>
            <div>: Sedang</div>
          </div>
          <div className="flex items-center">
            <div className="w-32">{"≥ 15"}</div>
            <div>: Berat</div>
          </div>
        </>
      )}

      {data.name === "PHQ-9" && (
        <>
          <div className="text-sm font-semibold">Keterangan</div>
          <div className="flex items-center">
            <div className="w-32">{"<10"}</div>
            <div>: Gejala Depresi Ringan</div>
          </div>
          <div className="flex items-center">
            <div className="w-32">10 - 14</div>
            <div>: Depresi Ringan</div>
          </div>
          <div className="flex items-center">
            <div className="w-32">15 - 19</div>
            <div>: Depresi Sedang</div>
          </div>
          <div className="flex items-center">
            <div className="w-32">{"≥ 20"}</div>
            <div>: Depresi Berat</div>
          </div>
        </>
      )}
    </div>
  );
};

export default GadPhqResult;
